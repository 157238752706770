.map-content {
  position: relative;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
}

#map {
  position: absolute;
}
