.MuiTypography-body1.map-legend-heading {
  font-weight: bold;
  padding-left: 2%;
}

.map-content>.map-overlay-legend {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ebebeb;
  z-index: 2;
  position: absolute;
  right: 605px;
  bottom: 25px;
  width: 300px;
}

.map-content>.map-overlay-legend-2 {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ebebeb;
  z-index: 2;
  position: absolute;
  right: 10px;
  bottom: 25px;
  width: 590px;
}

.map-content>.map-overlay-legend>.MuiTypography-body1 {
  padding: 1px;

}

.map-overlay-legend-colorbox {
  height: 22px;
}

.pin-icon {
  float: left;
  width: 22px;
  margin-right: 10px;
  border-radius: 50%;
}

.pin-icon.alert-red {
  border: 2px solid #ff0000;
}

.building-status {
  float: left;
  padding-left: 7px;
  width: 22px;
  font-size: 8px;
  line-height: 38px;
  margin-right: 1px;
  border: 1px #afafaf solid;
}

.building-status.grey-dark {
  background-color: #999999;
}

.building-status.emerald-dark {
  background-color: #59B17F;
}

.building-status.green-light {
  background-color: #DAE299;
}

.building-status.blue-light {
  background-color: #ADCDF0;
}

.building-status.yellow-dark {
  background-color: #ECAE65;
}
