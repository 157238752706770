.popup-content h4 {
  border-bottom: 1px solid black;
  margin: 0;
  padding: 10px;
}

.popup-content span {
  padding: 10px;
}

.popup-content {
  border-radius: 10px;
  padding: 0 10px 0 10px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.8);
}

.popup-content p {
  margin: 0;
}

.mapboxgl-popup-content {
  padding: 0;
  margin: 0;
  border-radius: 10px;
}
