@keyframes blink {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.8;
  }
  20% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


.parentDiv {
  width: 265px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}

.numberCircle {
  border-radius: 50%;
  animation: blink1 1s linear infinite;
  border: 2px solid red;
  color: #666;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
}

@keyframes blink1 {
  0% {
    width: 9px;
    height: 9px;
    border: 2px solid red;
    font-size: 0px;
  }
  5% {
    width: 9.5px;
    height: 9.5px;
    border: 2.1px solid red;
    font-size: 0px;
  }
  10% {
    width: 10px;
    height: 10px;
    border: 2.1px solid red;
    font-size: 0px;
  }
  15% {
    width: 10.5px;
    height: 10.5px;
    border: 2.2px solid red;
    font-size: 0px;
  }
  20% {
    width: 11px;
    height: 11px;
    border: 2.2px solid red;
    font-size: 0px;
  }
  25% {
    width: 11.5px;
    height: 11.5px;
    border: 2.3px solid red;
    font-size: 0px;
  }
  30% {
    width: 12px;
    height: 12px;
    border: 2.3px solid red;
    font-size: 0px;
  }
  35% {
    width: 12.5px;
    height: 12.5px;
    border: 2.4px solid red;
    font-size: 0px;
  }
  40% {
    width: 13px;
    height: 13px;
    border: 2.4px solid red;
    font-size: 0px;
  }
  45% {
    width: 13.5px;
    height: 13.5px;
    border: 2.5px solid red;
    font-size: 0px;
  }
  50% {
    width: 14px;
    height: 14px;
    border: 2.5px solid red;
    font-size: 12px;
  }
  55% {
    width: 14.5px;
    height: 14.5px;
    border: 2.6px solid red;
    font-size: 0px;
  }
  60% {
    width: 15px;
    height: 15px;
    border: 2.6px solid red;
    font-size: 16px;
  }
  65% {
    width: 15.5px;
    height: 15.5px;
    border: 2.7px solid red;
    font-size: 0px;
  }
  70% {
    width: 16px;
    height: 16px;
    border: 2.7px solid red;
    font-size: 19px;
  }
  75% {
    width: 16.5px;
    height: 16.5px;
    border: 2.8px solid red;
    font-size: 0px;
  }
  80% {
    width: 17px;
    height: 17px;
    border: 2.8px solid rgb(255, 100, 100);
    font-size: 22px;
  }
  85% {
    width: 17.5px;
    height: 17.5px;
    border: 2.9px solid rgb(255, 160, 160);
    font-size: 0px;
  }
  90% {
    width: 18px;
    height: 18px;
    border: 2.9px solid rgb(255, 180, 180);
    font-size: 25px;
  }
  95% {
    width: 18.5px;
    height: 18.5px;
    border: 3px solid rgb(255, 201, 201);
    font-size: 0px;
  }
  100% {
    width: 19px;
    height: 19px;
    border: 3px solid rgb(255, 255, 255);
    font-size: 28px;
  }
}
